<template>
  <div>
    <CyDetails
      ref="cy-details"
      can-cancel
      :on-cancel="() => { $router.push({ name: 'stacks' }) }"
      :can-save="formIsValid"
      :on-save="createStack"
      :loading="loading"
      :saving="creating"
      :save-btn-text="$t('createStack')">
      <template #details_formFullWidth>
        <v-container
          v-if="_.isEmpty(templates)"
          data-cy="no-templates"
          class="stack-template__list--empty d-flex flex-column justify-center align-center pt-12 mt-12">
          <v-icon
            size="48"
            class="mb-8 mt-12">
            fa-cubes
          </v-icon>
          <div
            class="no-templates__title"
            v-text="$t('noTemplates.title')"/>
          <div
            class="no-templates__text"
            v-text="$t('noTemplates.text')"/>
        </v-container>
        <v-container
          v-else
          fluid>
          <v-row align="start">
            <v-col>
              <h3 v-text="$t('selectBlueprint')"/>
            </v-col>
          </v-row>
          <v-row
            align="start"
            no-gutters>
            <v-col/>
            <v-col
              cols="5"
              class="mt-n5 pl-5">
              <div
                v-if="selectedStackTemplate"
                data-cy="stack-template-selection"
                class="stack-template__selection">
                <div
                  class="block-title mb-2"
                  v-text="$t('template')"/>
                <div
                  data-cy="stack-template"
                  class="stack-template">
                  <CyStackAvatar
                    class="mr-2"
                    size="32"
                    :stack="selectedStackTemplate"/>
                  <div class="stack-template__text">
                    <div
                      class="h6"
                      v-text="selectedStackTemplate.name"/>
                    <div v-text="selectedStackTemplate.description"/>
                  </div>
                </div>
                <div
                  class="change-template__btn mb-6"
                  @click="selectedStackTemplate = null"
                  v-text="$t('changeBlueprint')"/>
              </div>
              <div
                v-else
                data-cy="stack-template-list"
                class="stack-template__list">
                <div
                  v-for="template in templates"
                  :key="template.id"
                  data-cy="stack-template"
                  class="stack-template"
                  @click="getTemplateConfig(template)">
                  <CyStackAvatar
                    class="mr-2"
                    size="32"
                    :stack="template"/>
                  <div class="stack-template__text">
                    <div
                      class="h6"
                      v-text="template.name"/>
                    <div v-text="template.description"/>
                  </div>
                  <div class="loader__wrapper">
                    <v-progress-circular
                      v-if="templateConfigLoading === template.ref"
                      :size="18"
                      :width="2"
                      data-cy="template-loading-spinner"
                      indeterminate
                      class="ml-2"
                      color="secondary"/>
                  </div>
                </div>
                <CyAlert
                  v-if="!_.isEmpty(stackConfigErrors)"
                  :title="$t('errorFetchingTemplate')"
                  :content="$t('retryLater')"
                  theme="error"
                  closeable
                  @close="CLEAR_STACK_ERRORS('stackConfig')"/>
              </div>
              <v-item-group
                v-if="selectedStackTemplate"
                v-show="_.keys(stackConfig).length > 1"
                v-model="selectedUsecase"
                mandatory
                data-cy="usecases-list">
                <div
                  class="block-title mb-2"
                  v-text="$t('environment.useCase')"/>
                <v-item
                  #default="{ active, toggle }"
                  v-for="[key, { name, description, cloud_provider }] in _.toPairs(stackConfig)"
                  :key="key"
                  :value="key">
                  <div
                    data-cy="usecase"
                    :class="['usecase', { selected: active }]"
                    @click="toggle">
                    <v-radio-group
                      readonly
                      hide-details
                      class="ma-0 mr-4 pa-0"
                      :value="active ? 'true' : 'false'">
                      <v-radio
                        :ripple="false"
                        color="secondary"
                        value="true"/>
                    </v-radio-group>
                    <CyIconCredential
                      size="32"
                      :type="cloud_provider || key"/>
                    <div>
                      <div
                        class="h6"
                        v-text="name"/>
                      <div v-text="description"/>
                    </div>
                  </div>
                </v-item>
              </v-item-group>
            </v-col>
            <v-col/>
          </v-row>
        </v-container>
        <v-container
          v-if="selectedStackTemplate"
          fluid
          data-cy="general-section">
          <v-divider class="mb-8"/>
          <v-row align="start">
            <v-col>
              <h3 v-text="$t('general')"/>
            </v-col>
          </v-row>
          <v-row
            align="start"
            no-gutters>
            <v-col/>
            <v-col
              cols="5"
              class="mt-n5 pl-5">
              <CyWizardStackDetails
                ref="stackForm"
                :hidden-fields="['description', 'keywords']"
                @is-valid="formIsValid = $event"/>
            </v-col>
            <v-col/>
          </v-row>
        </v-container>
      </template>
    </CyDetails>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import CyDetails from '@/components/details.vue'
import CyStackAvatar from '@/components/stack-avatar.vue'
import CyWizardStackDetails from '@/components/wizard/stack-details.vue'
import { constructBreadcrumb } from '@/utils/helpers'

export default {
  name: 'CyPageStackFromBlueprint',
  components: {
    CyDetails,
    CyStackAvatar,
    CyWizardStackDetails,
  },
  breadcrumb () {
    const { orgCanonical } = this

    return constructBreadcrumb(this.$options.name, this.$t('createFromBlueprint'), [
      {
        label: this.$t('routes.stacksSection'),
        name: 'stacksSection',
      },
      {
        name: 'dashboard',
        params: { orgCanonical },
      },
    ])
  },
  header () {
    return {
      title: this.$t('createFromBlueprint'),
      description: {
        text: this.$t('quickStartText'),
      },
    }
  },
  data: () => ({
    templateConfigLoading: null,
    selectedStackTemplate: null,
    selectedUsecase: null,
    formIsValid: false,
    loading: true,
    creating: false,
  }),
  computed: {
    ...mapState({
      stackConfig: (state) => state.organization.stack.config,
      stackConfigErrors: (state) => state.organization.stack.errors.stackConfig,
      templates: (state) => state.organization.available.stacks,
      user: (state) => state.user.profile,
    }),
  },
  async created () {
    await Promise.all([
      this.FETCH_AVAILABLE({ keyPath: 'stacks', extraParams: [null, null, null, null, true] }),
      this.FETCH_AVAILABLE({ keyPath: 'catalogRepositories' }),
    ])
    this.loading = false
  },
  methods: {
    ...mapActions('organization', [
      'FETCH_AVAILABLE',
    ]),
    ...mapActions('organization/stack', [
      'GET_STACK_CONFIG',
      'CREATE_STACK_FROM_TEMPLATE',
    ]),
    ...mapMutations('organization/stack', [
      'CLEAR_STACK_ERRORS',
    ]),
    async getTemplateConfig (stack) {
      this.templateConfigLoading = stack.ref

      await this.GET_STACK_CONFIG({ stackRef: stack.ref })

      if (_.isEmpty(this.stackConfigErrors)) {
        this.selectedStackTemplate = stack
      }

      this.templateConfigLoading = null
    },
    async createStack () {
      this.creating = true
      const now = Date.now()
      await this.CREATE_STACK_FROM_TEMPLATE({
        stack: {
          author: `${this.user.given_name} ${this.user.family_name}`,
          template_ref: this.selectedStackTemplate.ref,
          use_case: this.selectedUsecase,
          created_at: now,
          updated_at: now,
          ...this.$refs.stackForm.stack,
        },
        $router: this.$router,
      })
      this.creating = false
    },
  },
  i18n: {
    messages: {
      en: {
        title: '@:routes.stackFromBlueprint',
        changeBlueprint: 'Change blueprint',
        createFromBlueprint: 'Create from blueprint',
        createStack: 'Create Stack',
        errorFetchingTemplate: 'Error while fetching the blueprint.',
        noTemplates: {
          title: 'No templates available',
          text: `Start by importing Cycloid's default templates or create your own.`,
        },
        selectBlueprint: 'Select blueprint',
        template: 'Template',
        quickStartText: 'Quick start a stack by selecting a blueprint. Using blueprint can help you learn how to write your first stacks.',
        retryLater: 'Please retry later or select another blueprint.',
      },
      es: {
        title: '@:routes.stackFromBlueprint',
        changeBlueprint: 'Cambiar plantilla',
        createFromBlueprint: 'Crear desde plantilla',
        createStack: 'Crear stack',
        errorFetchingTemplate: 'Error al obtener la plantilla.',
        noTemplates: {
          title: 'No hay plantillas disponibles',
          text: `Comience importando las plantillas predeterminadas de Cycloid o cree las suyas propias.`,
        },
        selectBlueprint: 'Seleccionar plantilla',
        template: 'Plantilla',
        quickStartText: 'Inicia rápidamente una stack seleccionando una plantilla. El uso de plantillas puede ayudarse a aprender a escribir sus primeras stacks.',
        retryLater: 'Vuelva a intentarlo más tarde o seleccione otra plantilla.',
      },
      fr: {
        title: '@:routes.stackFromBlueprint',
        changeBlueprint: 'Choisir un autre modèle',
        createFromBlueprint: `Créer à partir d'un modèle`,
        createStack: 'Créer une stack',
        errorFetchingTemplate: 'Erreur lors de la récupération du modèle.',
        noTemplates: {
          title: 'Aucun modèle disponible',
          text: `Commencez par importer les modèles par défaut de Cycloid ou créez les votres.`,
        },
        selectBlueprint: 'Sélectionner un modèle',
        template: 'Modèle',
        quickStartText: `Créez rapidement une stack en sélectionnant un modèle. L'utilisation de modèles peut vous aider à apprendre à écrire vos premières stacks.`,
        retryLater: 'Veuillez réessayer plus tard ou sélectionner un autre modèle.',
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.change-template__btn {
  color: cy-get-color("secondary");
  font-weight: $font-weight-bolder;
  cursor: pointer;
}

.stack-template,
.usecase {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  padding: 16px;
  padding-left: 8px;
  border: 1px solid cy-get-color("primary", "light-4");
  border-radius: 4px;
  color: cy-get-color("primary");

  .cy-stack-avatar {
    align-self: flex-start;
  }
}

.stack-template__list {
  .stack-template {
    justify-content: space-between;
    cursor: pointer;

    &__text {
      flex-grow: 1;
    }

    &:hover {
      background: cy-get-color("grey", "light-3");
    }
  }

  &--empty {
    color: cy-get-color("primary");

    .v-icon {
      color: cy-get-color("primary", "light-3");
    }

    .no-templates__title {
      font-weight: $font-weight-bolder;
    }
  }
}

.usecase {
  padding-left: 16px;
  cursor: pointer;

  &:hover {
    background: cy-get-color("grey", "light-3");
  }

  &.selected {
    border: 1px solid cy-get-color("secondary", "light-1");
    background: cy-get-color("secondary", "light-4");
  }

  ::v-deep .credential-icon {
    margin-right: 16px;
  }
}

.block-title {
  color: cy-get-color("grey", "dark-2");
}

.content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

::v-deep .stack-details__container {
  width: 100%;
  min-width: 100%;
}

::v-deep .cy-details {
  flex-grow: 1;

  &__content {
    height: 100%;
    padding: 0;
  }

  &__section {
    height: auto;

    & + & {
      margin-top: 32px;
    }
  }
}
</style>
